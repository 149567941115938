<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="serviceAnalysis-container common-padding">
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="common-screen-container">
      <div class="common-input-container">
        <span>所属店铺:</span>
        <el-select
          class="common-screen-input"
          v-model="params.shopId"
          @change="commonFun"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in shopList"
            :key="item.id"
            :label="item.shop_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>基准值:</span>
        <el-select
          class="common-screen-input"
          v-model="params.referenceType"
          @change="commonFun"
          placeholder="请选择"
          filterable
        >
          <el-option key="other" label="客户" value="other"> </el-option>
          <el-option key="system" label="系统" value="system"> </el-option>
        </el-select>
      </div>
      <!-- <div class="common-input-container">
        <span>客户名称:</span>
        <el-input
          class="common-screen-input"
          placeholder="请输入"
          v-model="params.keyword"
        ></el-input>
      </div>
      <el-button
        class="common-screen-btn"
        type="primary"
        @click="
          () => {
            shopKPIList();
          }
        "
        >查 询</el-button
      > -->
      <el-button class="common-screen-btn" plain @click="() => resetBtn()"
        >重 置</el-button
      >
      <br />
      <!-- <div class="common-input-container">
        <el-date-picker
          v-model="params.datePicker"
          :clearable="false"
          @change="
            () => {
              changeDate();
            }
          "
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <div class="common-input-container">
        <el-radio-group v-model="params.type" @change="changeRadio">
          <el-radio-button :label="1">近7天</el-radio-button>
          <el-radio-button :label="2">近30天</el-radio-button>
          <el-radio-button :label="3">近90天</el-radio-button>
          <el-radio-button :label="0">昨天</el-radio-button>
        </el-radio-group>
      </div> -->
      <div class="common-input-container">
        <common-lately-date @commonGetDate="commonGetDate" :commonRadio="commonRadio" :startDate="params.startDate" :endDate="params.endDate" ref="commonReset"></common-lately-date>
      </div>
    </div>
    <!-- <div class="common-clr-606266">客户名称： {{ $route.query.customerName }}  <el-button class="common-screen-btn" @click="() => download()"
      >导出数据表格</el-button
    ></div> -->
    <div class="common-clr-606266">
      <el-button class="common-screen-btn" @click="() => download()">导出数据表格</el-button>
    </div>

    <div>
      <div id="echartsLineA"></div>
      <div id="echartsLineB"></div>
      <div id="echartsLineC"></div>
      <div id="echartsLineD"></div>
    </div>
    <el-table class="common-table" :data="tableData" @sort-change="tableSort">
      <el-table-column
        prop="shopName"
        label="店铺名称"
        :formatter="tableColumn"
        width="160px"
      ></el-table-column>
      <el-table-column
        prop="platformName"
        label="所属平台"
        sortable="custom"
        :formatter="tableColumn"
        width="100"
      ></el-table-column>
      <el-table-column
        sortable="custom"
        prop="consultationNum"
        label="总咨询量"
        :formatter="tableColumn"
        width="120px"
      ></el-table-column>
      <el-table-column
        sortable="custom"
        prop="consultationAvgNum"
        label="日均咨询量"
        :formatter="tableColumn"
        width="120px"
      ></el-table-column>
      <el-table-column label="首响(s)" sortable="custom" prop="firsrTime" width="120px">
        <template slot-scope="scope">
          <span
            :class="
              scope.row.firstTimeFlag == '1' && scope.row.firsrTime ? 'warning' : ''
            "
            >{{ scope.row.firsrTime || "--" }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="平响(s)" sortable="custom" prop="avgTime" width="120px">
        <template slot-scope="scope">
          <span
            :class="scope.row.avgTimeFlag == '1' && scope.row.avgTime ? 'warning' : ''"
            >{{ scope.row.avgTime || "--" }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="问答比" sortable="custom" prop="qaRate" width="120px">
        <template slot-scope="scope">
          <span
            :class="scope.row.qaRateFlag == '0' && scope.row.qaRate ? 'warning' : ''"
            >{{
              scope.row.qaRate
                ? (scope.row.qaRate * 100).toFixed(2) == "NaN"
                  ? "--"
                  : (scope.row.qaRate * 100).toFixed(2) + "%"
                : "--"
            }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        label="邀评率"
        sortable="custom"
        prop="inviteComments"
        width="120px"
      >
        <template slot-scope="scope">
          <span
            :class="
              scope.row.inviteCommentsFlag == '1' && scope.row.inviteComments
                ? 'warning'
                : ''
            "
            >{{
              scope.row.inviteComments
                ? (scope.row.inviteComments * 100).toFixed(2) == "NaN"
                  ? "--"
                  : (scope.row.inviteComments * 100).toFixed(2) + "%"
                : "--"
            }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="30s应答率" sortable="custom" prop="thirtyResponseRate" width="140px">
        <template slot-scope="scope">
          <span >
            {{
              scope.row.thirtyResponseRate
                ? (scope.row.thirtyResponseRate * 100).toFixed(2) == "NaN"
                  ? "--"
                  : (scope.row.thirtyResponseRate * 100).toFixed(2) + "%"
                : "--"
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="三分钟回复率" sortable="custom" prop="threeMinuteResponseRate" width="140px">
        <template slot-scope="scope">
          <span >
            {{
              scope.row.threeMinuteResponseRate
                ? (scope.row.threeMinuteResponseRate * 100).toFixed(2) == "NaN"
                  ? "--"
                  : (scope.row.threeMinuteResponseRate * 100).toFixed(2) + "%"
                : "--"
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="五分钟回复率" sortable="custom" prop="fiveMinuteResponseRate" width="140px">
        <template slot-scope="scope">
          <span >
            {{
              scope.row.fiveMinuteResponseRate
                ? (scope.row.fiveMinuteResponseRate * 100).toFixed(2) == "NaN"
                  ? "--"
                  : (scope.row.fiveMinuteResponseRate * 100).toFixed(2) + "%"
                : "--"
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        sortable="custom"
        prop="responseRate"
        label="回复率"
        width="120px"
      >
        <template slot-scope="scope">
          <span>{{
              scope.row.responseRate
                ? (scope.row.responseRate * 100).toFixed(2) == "NaN"
                  ? "--"
                  : (scope.row.responseRate * 100).toFixed(2) + "%"
                : "--"
            }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        sortable="custom"
        prop="conversionRate"
        label="转化率"
        width="120px"
      >
        <template slot-scope="scope">
          <span>{{
              scope.row.conversionRate
                ? (scope.row.conversionRate * 100).toFixed(2) == "NaN"
                  ? "--"
                  : (scope.row.conversionRate * 100).toFixed(2) + "%"
                : "--"
            }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="满意度" sortable="custom" prop="satisfaction" width="120px">
        <template slot-scope="scope">
          <span
            :class="
              scope.row.satisfactionFlag == '0' && scope.row.satisfaction ? 'warning' : ''
            "
            >{{ scope.row.satisfaction || "--" }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="satisfactionDifference"
        :formatter="tableColumn"
        label="满意度差值"
        sortable="custom"
        width="120px"
      ></el-table-column>
      <el-table-column
        label="工单错误率"
        sortable="custom"
        prop="orderErrorRate"
        width="120px"
      >
        <template slot-scope="scope">
          <span
            :class="
              scope.row.orderErrorRateFlag == '1' && scope.row.orderErrorRate
                ? 'warning'
                : ''
            "
            >{{
              scope.row.orderErrorRate
                ? (scope.row.orderErrorRate * 100).toFixed(2) == "NaN"
                  ? "--"
                  : (scope.row.orderErrorRate * 100).toFixed(2) + "%"
                : "--"
            }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="orderErrorRateDifference"
        :formatter="tableColumn"
        sortable="custom"
        label="工单错误率差值"
        width="160px"
      ></el-table-column>
      <el-table-column
        label="质检错误率"
        sortable="custom"
        prop="qualityErrorRate"
        width="120px"
      >
        <template slot-scope="scope">
          <span
            :class="
              scope.row.qualityErrorRateFlag == '1' && scope.row.qualityErrorRate
                ? 'warning'
                : ''
            "
            >{{
              scope.row.qualityErrorRate
                ? (scope.row.qualityErrorRate * 100).toFixed(2) == "NaN"
                  ? "--"
                  : (scope.row.qualityErrorRate * 100).toFixed(2) + "%"
                : "--"
            }}</span
          >
        </template>
      </el-table-column>

      <el-table-column
        prop="qualityErrorRateDifference"
        :formatter="tableColumn"
        label="质检错误率差值"
        sortable="custom"
        width="160px"
      ></el-table-column>
      <el-table-column
        label="服务高压线"
        sortable="custom"
        prop="serviceHighVoltage"
        width="120px"
      >
        <template slot-scope="scope">
          <span
            :class="
              scope.row.serviceHighVoltageFlag == '0' && scope.row.serviceHighVoltage
                ? 'warning'
                : ''
            "
            >{{ scope.row.serviceHighVoltage || "--" }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        label="遗漏客诉"
        sortable="custom"
        prop="missFeedback"
        width="120px"
      >
        <template slot-scope="scope">
          <span
            :class="
              scope.row.missFeedback == '0' && scope.row.missFeedback ? 'warning' : ''
            "
            >{{ scope.row.missFeedback || "--" }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="操作" width="140" fixed="right">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="
              () => {
                $router.push({
                  path: '/data/customer-service-analysis/service-kpi',
                  query: {
                    customerId: $route.query.customerId,
                    companyId: $route.query.companyId,
                    customerName: $route.query.customerName,
                    shopId: scope.row.shopId,
                    shopName: scope.row.shopName,
                    startDate: params.startDate,
                    commonRadio: commonRadio,
                    endDate: params.endDate,
                    referenceType: params.referenceType,
                    guestFlag: params.guestFlag
                  },
                });
              }
            "
            >客服KPI</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.page"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import { shopKPIList, shopKPICharts } from "../../service/dataInfo.js";
import { getShopList } from "../../service/common.js";
import { tableColumn, DateTransform, getLatelyDay, Config } from "../../utils/index.js";
import CommonLatelyDate from "../../components/common/commonLatelyDate.vue"
export default {
  components: { Breadcrumb, CommonLatelyDate },
  data() {
    return {
      loading: false,
      tableColumn,
      DateTransform,
      shopList: [], // 所属公司下拉
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "数据", isLink: false },
        { title: "店铺服务分析", isLink: true, url: "/data/customer-service-analysis" },
        { title: "店铺KPI", isLink: false },
      ],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      commonRadio: this.$route.query.commonRadio,
      params: {
        companyId: this.$route.query.companyId,
        customerId: this.$route.query.customerId,
        startDate: this.$route.query.startDate,
        endDate: this.$route.query.endDate,
        referenceType: this.$route.query.referenceType,
        guestFlag: this.$route.query.guestFlag,
        page: 1,
        pageSize: 10,
      },
      tableData: [],
      pickerOptions: {
        // 指派分公司的禁用时间范围
        // disabledDate: (time) => {
        //   return (
        //     time.getTime() <
        //       new Date(this.$route.query.startDate).getTime() - 24 * 60 * 60 * 1000 ||
        //     time.getTime() > new Date(this.$route.query.endDate).getTime()
        //   );
        // },
      },
    };
  },
  created() {
    this.shopKPIList();
    this.getShopList();
  },
  methods: {
    commonGetDate (startTime, endTime, commonRadio) {
      this.params.startDate = startTime
      this.params.endDate = endTime
      this.commonRadio = commonRadio
      this.shopKPIList()
    },
    download() {
      // 导出店铺KPI
      let params = { ...this.params };
      // params.startDate = DateTransform(params.datePicker[0]);
      // params.endDate = DateTransform(params.datePicker[1]);
      // delete params.datePicker;
      delete params.page;
      delete params.pageSize;
      if (!params.type) {
        delete params.type;
      }
      let url = Config.api.url + "/excel/projectKPIExport";
      let paramArr = [];
      for (let item in params) {
        if (params[item]) {
          paramArr.push(item + "=" + params[item]);
        }
      }
      url = url + "?" + paramArr.join("&");
      window.location.href = url;
    },
    async shopKPICharts() {
      let params = { ...this.params };
      // if (params.datePicker && params.datePicker.length) {
      //   params.startDate = DateTransform(params.datePicker[0]);
      //   params.endDate = DateTransform(params.datePicker[1]);
      // }
      if (!params.type) {
        delete params.type;
      }
      if (!params.sortType && params.sortType != 0) {
        delete params.sortType;
      }
      let resData = (await shopKPICharts(params)).data;
      let echartsDate = [];
      let echartsData = [];
      let arr = ["总咨询量", "首响（s）", "平响（s）", "满意度", "问答比", "邀评率", "回复率", "转化率"];
      let arrKey = [
        "consultationNum",
        "firsrTime",
        "avgTime",
        "satisfaction",
        "qaRate",
        "inviteComments",
        "responseRate",
        "conversionRate"
      ];
      // if (resData && resData.length) {
        resData.map((item) => {
          echartsDate.push(item.excelDate);
        });
        arr.map((arrItem, arrIndex) => {
          echartsData.push({
            data: [],
            name: arrItem,
            type: "line",
            markLine: {
              data: [],
            },
            // stack: "总量",
          });
          resData.map((item, index) => {
            let keyStr = "";
            switch (arrKey[arrIndex]) {
              case "inviteComments":
                keyStr = "inviteCommentsReference";
                break;
              case "qaRate":
                keyStr = "qaRateReference";
                break;
              case "firsrTime":
                keyStr = "firstTimeReference";
                break;
              case "avgTime":
                keyStr = "avgTimeReference";
                break;
              case "satisfaction":
                keyStr = "satisfactionReference";
                break;
            }
            if (arrKey[arrIndex] == "inviteComments" || arrKey[arrIndex] == "qaRate" || arrKey[arrIndex] == "responseRate" || arrKey[arrIndex] == "conversionRate" || (arrKey[arrIndex] == "satisfaction" && this.params.guestFlag != '0')) {
              echartsData[arrIndex].data.push((item[arrKey[arrIndex]] * 100).toFixed(2));
              if (index + 2 < resData.length && keyStr) {
                echartsData[arrIndex].markLine.data.push([
                  {
                    symbol: "none",
                    coord: [index, Number(item[keyStr] * 100)],
                  },
                  {
                    symbol: "none",
                    coord: [index + 1, Number(resData[index + 1][keyStr] * 100)],
                  },
                ]);
              } else if (index + 1 < resData.length && keyStr) {
                echartsData[arrIndex].markLine.data.push([
                  {
                    symbol: "none",
                    coord: [index, Number(item[keyStr] * 100)],
                  },
                  {
                    symbol: "none",
                    label: {
                      position: "end",
                      formatter: arrItem + " " + Number(resData[index + 1][keyStr] * 100) + "%",
                    },
                    coord: [index + 1, Number(resData[index + 1][keyStr] * 100)],
                  },
                ]);
              }
            } else {
              echartsData[arrIndex].data.push(item[arrKey[arrIndex]]);
              if (index + 2 < resData.length && keyStr) {
                echartsData[arrIndex].markLine.data.push([
                  {
                    symbol: "none",
                    coord: [index, Number(item[keyStr])],
                  },
                  {
                    symbol: "none",
                    coord: [index + 1, Number(resData[index + 1][keyStr])],
                  },
                ]);
              } else if (index + 1 < resData.length && keyStr) {
                if (arrKey[arrIndex] == "firsrTime" || arrKey[arrIndex] == "avgTime") {
                  echartsData[arrIndex].markLine.data.push([
                    {
                      symbol: "none",
                      coord: [index, Number(item[keyStr])],
                    },
                    {
                      symbol: "none",
                      label: {
                        position: "end",
                        formatter:
                          arrItem.split("（s）")[0] +
                          " " +
                          Number(resData[index + 1][keyStr]) +
                          "s",
                      },
                      coord: [index + 1, Number(resData[index + 1][keyStr])],
                    },
                  ]);
                } else {
                  echartsData[arrIndex].markLine.data.push([
                    {
                      symbol: "none",
                      coord: [index, Number(item[keyStr])],
                    },
                    {
                      symbol: "none",
                      label: {
                        position: "end",
                        formatter: arrItem + " " + Number(resData[index + 1][keyStr]),
                      },
                      coord: [index + 1, Number(resData[index + 1][keyStr])],
                    },
                  ]);
                }
              }
            }
          });
        });
      // }
      let echartsDataA = echartsData.slice(0, 1);
      let echartsDataB = echartsData.slice(4);
      let echartsDataC = echartsData.slice(1, 3);
      let echartsDataD = echartsData.slice(3, 4);
      this.echartsLine("echartsLineA", echartsDataA, echartsDate, ["总咨询量"]);
      this.echartsLine("echartsLineB", echartsDataB, echartsDate, ["问答比", "邀评率", "回复率", "转化率"]);
      this.echartsLine("echartsLineC", echartsDataC, echartsDate, [
        "首响（s）",
        "平响（s）",
      ]);
      this.echartsLine("echartsLineD", echartsDataD, echartsDate, ["满意度"]);
    },
    echartsLine(byId, echartsData, echartsDate, items) {
      // 折线图
      let yAxis;
      if (byId == "echartsLineB") {
        yAxis = {
          type: "value",
          axisLabel: {
            formatter: "{value} %",
          },
        };
      } else if (byId == "echartsLineD") {
        if (this.params.guestFlag == '0') {
          yAxis = { type: "value", max: 5 };
        } else {
          yAxis = {
            type: "value",
            max: 100,
            axisLabel: {
              formatter: "{value} %",
            },
          };
        }
      } else {
        yAxis = { type: "value" };
      }
      let myChart = this.$echarts.init(document.getElementById(byId));
      let option;
      if (echartsDate && echartsDate.length) {
        option = {
          title: {
            text: byId == "echartsLineA" ? "总咨询量" : byId == "echartsLineB" ? '问答比/邀评率': byId == "echartsLineC" ? '首响（s）/平响（s）' : '满意度',
          },
          width: "80%",
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: items,
          },
          grid: {
            left: "3%",
            right: "6%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: echartsDate,
          },
          yAxis: yAxis,
          series: echartsData,
        };
      } else {
        option = {
          title: {
            text: "暂无数据",
            x: "center",
            y: "40%",
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: echartsDate,
          },
          yAxis: {
            type: "value",
          },
          series: echartsData,
        };
      }

      myChart.setOption(option);
    },
    async getShopList() {
      let resData = (await getShopList({companyId: this.$route.query.companyId})).data;
      this.shopList = resData;
    },
    tableSort(e) {
      // 表格项排序
      let params = { ...this.params };
      params.sortField = e.prop;
      console.log(e.prop);
      switch (e.order) {
        case "ascending":
          params.sortType = 1;
          break;
        case "descending":
          params.sortType = 2;
          break;
        default:
          params.sortType = null;
      }
      this.params = params;
      this.shopKPIList();
    },
    changeDate() {
      // 选择时间时清空近几天类型选择
      this.params.type = null;
      this.shopKPIList();
    },
    changeRadio(e) {
      let date;
      switch (e) {
        case 0:
          date = 0;
          break;
        case 1:
          date = 7;
          break;
        case 2:
          date = 30;
          break;
        case 3:
          date = 90;
          break;
      }
      this.params.datePicker = getLatelyDay(date);
      this.shopKPIList();
    },
    changeBreadcrumb(num) {
      // 面包屑点击事件
      this.nowBreadcrumb = num;
    },
    commonFun() {
      this.params.page = 1;
      this.params.pageSize = 10;
      this.shopKPIList();
    },
    handleSelectionChange(row) {
      console.log(row);
    },
    resetBtn() {
      this.commonRadio = this.$route.query.commonRadio
      this.params = {
        companyId: this.$route.query.companyId,
        customerId: this.$route.query.customerId,
        startDate: this.$route.query.startDate,
        endDate: this.$route.query.endDate,
        referenceType: this.$route.query.referenceType,
        guestFlag: this.$route.query.guestFlag,
        page: 1,
        pageSize: 10,
      };
      this.$refs.commonReset.resetFun(this.$route.query.commonRadio, this.$route.query.startDate, this.$route.query.endDate)
      this.shopKPIList();
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.page = e;
      }
      this.shopKPIList();
    },
    async shopKPIList() {
      // 子账号列表
      this.shopKPICharts();
      this.loading = true;
      let params = { ...this.params };
      // if (params.datePicker && params.datePicker.length) {
      //   params.startDate = DateTransform(params.datePicker[0]);
      //   params.endDate = DateTransform(params.datePicker[1]);
      // }
      if (!params.type) {
        delete params.type;
      }
      if (!params.sortType && params.sortType != 0) {
        delete params.sortType;
      }
      let resData = (await shopKPIList({ ...params })).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = resData.list;
      this.loading = false;
    },
  },
};
</script>
<style lang="less" scoped>
.serviceAnalysis-container {
  text-align: left;
  #echartsLineA,
  #echartsLineC {
    width: 49%;
    height: 400px;
    display: inline-block;
    margin: 50px 0 20px 0;
  }
  #echartsLineB,
  #echartsLineD {
    width: 49%;
    display: inline-block;
    margin: 50px 0 20px 0;
    height: 400px;
  }
  .warning {
    color: #f00;
  }
  .breadcrumbStyle {
    color: #00000073;
    font-weight: 500;
  }
  .breadcrumbCursor {
    cursor: pointer;
    &:hover {
      color: #409eff;
    }
  }
}
</style>
